import { FeatureFlags } from '@accredible-frontend-v2/models';
import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { RecipientPortalApiService } from '../../shared/services/rp-api/rp-api.service';

@Injectable({
  providedIn: 'root',
})
export class FeaturesService extends RecipientPortalApiService {
  loadFeatures(organizationId: number = null): Observable<FeatureFlags> {
    return this._get(`/v1/recipient/features?organization_id=${organizationId}`).pipe(
      shareReplay(1),
      map((res) => this._handleResponse(res, 'features')),
      catchError((res) => this._handleError(res, true)),
    );
  }
}
