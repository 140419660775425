import { environment } from '@accredible-frontend-v2/envs';
import { AccredibleUser, ActiveFeatureFlags } from '@accredible-frontend-v2/models';
import { AccountsRedirectionKey } from '@accredible-frontend-v2/services/accounts-redirection';
import { AccredibleAuthenticationApiService } from '@accredible-frontend-v2/services/authentication';
import { AccredibleBrowserStorageService } from '@accredible-frontend-v2/services/browser-storage';
import { AccredibleCookiesService } from '@accredible-frontend-v2/services/cookies';
import {
  AccredibleLanguageService,
  getAvailableLanguages,
  registerSupportedLocalesData,
} from '@accredible-frontend-v2/services/language';
import { AccredibleSeoService } from '@accredible-frontend-v2/services/seo';
import { ThemeGeneratorService } from '@accredible-frontend-v2/services/theme-generator';
import { isUuidSlashEditRoute } from '@accredible-frontend-v2/utils/credential-id-helper';
import { addGainsightPXCode } from '@accredible-frontend-v2/utils/gainsight-px';
import { addGoogleTagManager } from '@accredible-frontend-v2/utils/google-tag-manager-helper';
import { addMicrosoftClarity } from '@accredible-frontend-v2/utils/microsoft-clarity';
import { accredibleCustomThemesMetadata } from '@accredible-frontend-v2/utils/themes';
import { WindowHelper } from '@accredible-frontend-v2/utils/window-helper';
import { DOCUMENT } from '@angular/common';
import { inject, Inject, Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as CredentialsActions from '../shared/stores/credentials/credentials.actions';
import { CredentialsState } from '../shared/stores/credentials/credentials.reducer';
import { ThemeHelper } from '../themes/theme.helper';
import { FeaturesService } from './services/features.service';
import { map } from 'rxjs/operators';
import { firstValueFrom } from 'rxjs';

const MS_CLARITY_ID = 'j4qk5vryd9';

@Injectable()
export class RecipientPortalLoadService {
  private readonly _featuresService = inject(FeaturesService);

  readonly accredishareWidget$ = this._featuresService
    .loadFeatures()
    .pipe(map((features) => features[ActiveFeatureFlags.ACCREDISHARE_WIDGET]));

  constructor(
    @Inject(DOCUMENT) private readonly _document: Document,
    private readonly _authenticationApi: AccredibleAuthenticationApiService,
    private readonly _router: Router,
    private readonly _cookies: AccredibleCookiesService,
    private readonly _language: AccredibleLanguageService,
    private readonly _browserStorage: AccredibleBrowserStorageService,
    private readonly _domSanitizer: DomSanitizer,
    private readonly _matIconRegistry: MatIconRegistry,
    private readonly _credentialsStore: Store<CredentialsState>,
    private readonly _themeGenerator: ThemeGeneratorService,
    private readonly _seo: AccredibleSeoService,
  ) {}

  initializeApp(): Promise<boolean> {
    // Pass the themeHelper into the seo service to be used to get the theme metadata
    this._seo.setTheme(ThemeHelper);

    return new Promise<boolean>((resolve) => {
      this._themeGenerator
        .setTheme(ThemeHelper)
        .then((themeName: string) => {
          this._setLanguages(themeName);
          resolve(true);
        })
        .catch(() => {
          console.error('Theme styles failed to load');
          resolve(false);
        });

      // We can do pre-app initialization here
      this._registerAccredibleSvgIcons();

      const hostName = window.location.hostname;

      // TODO(Joao): Work on a more generic solution
      // Workaround to initialize GTM for all the domains that are not certification.web3.wharton.upenn.edu
      if (hostName !== 'certification.web3.wharton.upenn.edu') {
        addGoogleTagManager();
      }

      addMicrosoftClarity(MS_CLARITY_ID);
      addGainsightPXCode(environment.recipientGainsightTagKey);

      firstValueFrom(this.accredishareWidget$).then((accredishareWidgetEnabled) => {
        if (!accredishareWidgetEnabled) {
          this._loadGetSocial();
        }
      });

      // Load domain whitelabel settings if they exist, else it will default to credential.net
      this._credentialsStore.dispatch(CredentialsActions.loadOrganizationSettings());
    });
  }

  validateAuthenticationToken(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      // Check if token is in URL query params
      const urlParams = new URLSearchParams(this._document.location.search);
      if (urlParams.has('token') || urlParams.has('jwt')) {
        if (this._document.location.pathname === '/auth') {
          this._loginWithOneTimeToken(urlParams.get('token')).then((isLoggedIn) =>
            resolve(isLoggedIn),
          );
        } else if (this._document.location.pathname === '/user/jwt') {
          this._loginWithSSOToken(urlParams.get('jwt')).then((isLoggedIn) => resolve(isLoggedIn));
        } else if (isUuidSlashEditRoute(this._document.location.pathname)) {
          const credentialIdOrUuid = this._document.location.pathname.split('/')[1];
          this._loginWithSSOToken(
            urlParams.get('jwt'),
            +urlParams.get('provider'),
            credentialIdOrUuid,
          ).then((isLoggedIn) => resolve(isLoggedIn));
        } else {
          // User is logged in if token has not expired
          this._cookies.set(AccountsRedirectionKey.SESSION_TOKEN_COOKIE, urlParams.get('token'));
          this._checkSession().then((isLoggedIn) => resolve(isLoggedIn));
        }

        // Remove token from URL query params
        WindowHelper.removeQueryParamFromUrl('token', urlParams);
      } else if (this._authenticationApi.getSessionToken()) {
        // If session token exists, user is logged in
        this._checkSession().then((isLoggedIn) => resolve(isLoggedIn));
      } else {
        // User is not logged in
        resolve(false);
      }
    });
  }

  private _loginWithOneTimeToken(token: string): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      // Attempt to log in user with one-time token
      this._authenticationApi.loginWithOneTimeToken(token).subscribe({
        // Success: User is logged in
        next: () => resolve(true),
        // Error (401): One-time token is invalid
        error: () => {
          // Clear any pre-existing login
          this._cookies.delete(AccountsRedirectionKey.SESSION_TOKEN_COOKIE);
          this._router.navigate(['jwt-expired']).then();
          resolve(false);
        },
      });
    });
  }

  private _loginWithSSOToken(
    token: string,
    issuerId?: number,
    credentialIdOrUuid?: number | string,
  ): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      // Attempt to log in user with jwt token
      this._authenticationApi.loginWithSSOToken(token, issuerId, credentialIdOrUuid).subscribe({
        // Success: User is logged in
        next: ({ user }) => {
          this._checkIfEmbedded(user);
          resolve(true);
        },
        // Error (401): Jwt token is invalid
        error: () => {
          // Clear any pre-existing login
          this._cookies.delete(AccountsRedirectionKey.SESSION_TOKEN_COOKIE);
          resolve(false);
        },
      });
    });
  }

  private _checkSession(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      // Test if token has not expired
      this._authenticationApi.checkSession().subscribe({
        // Success: User is logged in
        next: () => resolve(true),
        // Error (401): The session token has expired or the user logged out in other app
        // Our ApiService will delete our session_token from cookies and redirect the user to the login page
        error: () => resolve(false),
      });
    });
  }

  private _setLanguages(themeName: string): void {
    // Register supported languages
    let supportedLanguages;
    // If previewing a theme we only support en language
    if (environment.theming) {
      supportedLanguages = getAvailableLanguages(['en']);
    } else {
      supportedLanguages = getAvailableLanguages(
        accredibleCustomThemesMetadata[themeName].languages,
      );
    }

    registerSupportedLocalesData(supportedLanguages);

    // Set active language
    const urlParams = new URLSearchParams(this._document.location.search);
    const languageCode = urlParams.get(AccountsRedirectionKey.LANGUAGE);
    this._language.setLanguage(languageCode, themeName);
    if (languageCode) {
      this._browserStorage.set(AccountsRedirectionKey.LANGUAGE, languageCode);
    }
    // Once set we can remove the language from the url query params
    WindowHelper.removeQueryParamFromUrl(AccountsRedirectionKey.LANGUAGE, urlParams);
  }

  private _registerAccredibleSvgIcons(): void {
    this._matIconRegistry.addSvgIcon(
      'user',
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/user.svg'),
    );
  }

  private _loadGetSocial(): void {
    const po = this._document.createElement('script');
    po.type = 'text/javascript';
    po.async = true;
    po.src = 'https://api.at.getsocial.io/get/v1/' + environment.getSocialId + '/gs_async.js';
    const s = this._document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(po, s);
  }

  private _checkIfEmbedded(user: AccredibleUser): void {
    const urlParams = new URLSearchParams(this._document.location.search);
    if (urlParams.has('embed_wallet') && urlParams.get('embed_wallet') === 'true') {
      // Storing the session token in local storage if in iframe to keep authentication
      // when opening any credential in a new tab
      this._browserStorage.set(
        AccountsRedirectionKey.SESSION_TOKEN_COOKIE,
        this._cookies.get(AccountsRedirectionKey.SESSION_TOKEN_COOKIE),
      );
      const { username } = user;
      this._router.navigate([`/embed/profile/${username}/wallet`]).then();
    }
  }
}
